import { Button, Grid, HStack, Heading, Progress, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { format } from "date-fns";
import { theme } from "../chakra-theme";
import { CheckCircleIcon, LockIcon } from "@chakra-ui/icons";

export const TierTracker: React.FC = observer(() => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      authStore.getUser();
    }
  }, [ user ]);
  let val = user?.tiers[1].next ? 0 : 100
  if (user?.tiers[1].next) {
    val = 100 * user.spendable / user.tiers[1].next.points;
  }
  const nextTier = user?.tiers[1].next ? user?.tiers[1].next : {
    id: user?.tiers[1].id + '2',
    name: user?.tiers[1].name,
    points: user?.tiers[1].threshold || 0,
  }
  const isMaxTier = !user?.tiers[1].next;
  return (
    <Grid gap="1em" bg="brand.500" color="brand.100" alignItems="center" justifyItems="center" padding="1em" templateRows="2em 1em 3em auto auto">
      <Heading size="xl">WELCOME BACK, {user?.first_name.toUpperCase()}!</Heading>
      <Heading size="xs">Part of the Community since <span style={{color: theme.colors.brand[700]}}>{user?.optin_at && format(user.optin_at * 1000, 'LLLL y')}</span></Heading>
      <Grid alignItems="center" gap="1em" justifyContent="center" templateColumns="1fr 1fr 1fr">
        <Heading size="xs">LOYALTY LEVELS</Heading>
        <HStack>
          <CheckCircleIcon />
          <Grid>
            <Heading size="sn">{user?.tiers[1].name.toUpperCase()}</Heading>
            <Heading size="xs" color="brand.700">Unlocked!</Heading>
          </Grid>
        </HStack>
        <HStack>
          {isMaxTier ? <CheckCircleIcon /> : <LockIcon />}
          <Grid>
            <Heading size="sm">{nextTier.name?.toUpperCase()}</Heading>
            <Heading size="xs" color="brand.700">{isMaxTier ? 'Unlocked!' : `${nextTier.points - user.spendable} Points to Unlock`}</Heading>
          </Grid>
        </HStack>
      </Grid>
      <Grid w="100%">
        <HStack w="100%" justifyContent="space-between">
          <Text>0</Text>
          <Text>{nextTier.points}</Text>
        </HStack>
        <Progress rounded="1em" value={val} />
      </Grid>
      <Button variant="solidSecondary" onClick={() => document.location.pathname = '/activity-history'}>View My Activity History</Button>
    </Grid>
  )
});