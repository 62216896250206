import { Button, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Reward } from "../store/RewardsStore";

type RewardItemProps = {
  reward: Reward;
  hasEnoughPoints: boolean;
  onClaim: () => void;
  spendable: number;
}

export const RewardItem: React.FC<RewardItemProps> = ({ reward, hasEnoughPoints, onClaim, spendable }) => {
  return (
    <Grid borderRadius="4px" overflow="hidden" m="0 1em">
      <Grid borderRadius="10px" overflow="hidden" gap="0.5em">
        <Grid>
          <Image opacity={hasEnoughPoints ? 1 : 0.5} src={reward.image.medium} />
          {!hasEnoughPoints && 
            <Text textAlign="center" bg="brand.700">EARN {reward.points - spendable} MORE POINTS</Text>
          }
        </Grid>
        <Text color="brand.500" size="lg" fontWeight="bold" textAlign="end">
          {reward.points} Points!
        </Text>
        <Text size="lg" fontWeight="bold">{reward.title}</Text>
        <Text>{reward.description}</Text>
        <Button
          variant="solidSecondary"
          loadingText="Loading..."
          isDisabled={!hasEnoughPoints}
          onClick={hasEnoughPoints ? onClaim : undefined}
          placeSelf="center"
          p="0.5em 3em"

        >
            CLAIM REWARD
        </Button>
        </Grid>
      </Grid>
  )
}