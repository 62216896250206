import { Box, Grid, Image } from '@chakra-ui/react';
import React from 'react';
import { LoginForm } from '../components/LoginForm';
import loginImg from '../assets/login.jpg';

export const Login: React.FC = () => {
  return (
    <Grid templateRows="1fr 1fr" h="100vh">
      <Grid position="relative" p="0 1.5em" gap="2em" h="100%">
        <Image w="200%" h="100%" zIndex="-1" position="absolute" src={loginImg} />
      </Grid>
      <Box borderTopRadius="1em">
        <LoginForm />
      </Box>
    </Grid>
  )
}