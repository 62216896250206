import { Grid, Image } from "@chakra-ui/react";
import React from "react";
import { HomeExtraPoints } from "../components/HomeExtraPoints";
import { Page } from "../components/Page";
import { TierTracker } from "../components/TierTracker";
import bannerImg from '../assets/login.jpg';

export const Home: React.FC = () => {
  return (
    <Page>
      <Grid templateRows="1fr 1f 1fr">
        <Grid h="300px" backgroundSize="cover" backgroundPosition="center" backgroundImage={bannerImg} />
        <TierTracker />
        <HomeExtraPoints />
      </Grid>
    </Page>
  )
}