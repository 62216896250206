import { Grid, Text, Heading, Box, Image, Progress, Button, ProgressLabel } from "@chakra-ui/react";
import React from "react";
import { format } from "date-fns";
import { Challenge } from "../store/ChallengesStore";

type ChallengeCardProps = {
  challenge: Challenge;
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge }) => {
  const totalCompleted = challenge.progress.criteria.reduce((acc, c) => acc += c.current, 0);
  const totalCriteria = challenge.progress.criteria.reduce((acc, c) => acc += c.threshold, 0);
  const percentage = totalCompleted > 0 ? 100 * totalCompleted / totalCriteria : 0;  
  return (
    <Grid
      position="relative"
      borderRadius="10px"
      overflow="hidden"
    >
      <Box>
        <Image src={challenge.image.medium} />
      </Box>
      <Progress h="20px" value={percentage}>
        <ProgressLabel color="brand.500">{totalCompleted} / {totalCriteria} Completed!</ProgressLabel>
      </Progress>
      <Grid>
        <Text fontStyle="italic" alignSelf="end" color="brand.100">Until {format(new Date(challenge.ends_at), 'MMM eo')}</Text>
        <Heading textAlign="center" size="sm">{challenge.title}</Heading>
        <Text>{challenge.description}</Text>
        <Text fontWeight="bold" color="brand.500" textAlign="end" m=".5em 0">Win {challenge.points} Points!</Text>
        <Button p="0.5em 3em" placeSelf="center" variant="solidSecondary">{challenge.cta_label}</Button>
      </Grid>
    </Grid>
  )
}