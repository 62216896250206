import { Button, Grid, Image, Link, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HamburgerIcon } from '@chakra-ui/icons'
import { SideBar } from "./SideBar";
import logoImg from '../assets/logo-yellow.png';

const routes: { id: string; label: string; path: string; renderCustom?: () => JSX.Element }[] = [
  {
    id: 'side-1',
    label: 'Home',
    path: '/',
  },
  {
    id: 'side-2',
    label: 'Activity History',
    path: '/activity-history',
  },
  {
    id: 'side-3',
    label: 'Redeem Rewards',
    path: '/rewards',
  },
  {
    id: 'side-4',
    label: 'Log Out',
    path: '/login',
    renderCustom: () => (
      <Link
        fontSize="1.5em"
        onClick={() => {
          localStorage.removeItem('user');
          document.location = '/login';
        }}
        key="side-4"
      >
        Log Out
      </Link>
    )
  }
]

export const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Grid bg="brand.500" color="brand.100" alignItems="center" pl='1em' templateColumns="1fr 4em">
      <Image h="2em" src={logoImg} />
      <Button
        w="4em"
        h="4em"
        variant="unstyled"
        leftIcon={<HamburgerIcon />}
        onClick={onOpen}
      />
      <SideBar isOpen={isOpen} onClose={onClose} routes={routes} />
    </Grid>
  )
}