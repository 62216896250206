import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  RouterProvider,
} from "react-router-dom";
import './App.css'
import { router } from './Router';
import { theme } from './chakra-theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
