import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Image,
  Link
} from "@chakra-ui/react";
import logoDark from '../assets/logo1.png';

type MenuItemData = {
  id: string;
  label: string;
  path: string;
  onPress?: (arg: string) => void;
  renderCustom?: (arg: string) => JSX.Element;
}

type SideBarProps = {
  isOpen: boolean;
  onClose: () => void;
  routes: MenuItemData[];
}

export const SideBar: React.FC<SideBarProps> = ({ isOpen, onClose, routes }) => {
  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton size="lg" />
        <DrawerHeader>
          <Image h="2em" src={logoDark} />
        </DrawerHeader>
        <DrawerBody>
          <Grid justifyItems="end" gap="1em">
            {routes.map((route) => {
              if (route.renderCustom) {
                return route.renderCustom(route.label);
              }
              return <Link fontSize="1.5em" key={route.id} href={route.path}>{route.label}</Link>
            })}
          </Grid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}