import { makeAutoObservable } from "mobx";
import { AntavoImage } from "./shared/Image";

export interface Reward {
  type: string;
  starts_at: string;
  ends_at: string;
  id: string;
  title: string;
  description: string;
  image: AntavoImage;
  points: number;
}

class RewardsStore {
  rewards: Reward[] = [];
  isLoading: boolean = false;
  rewardApiUrl?: string = process.env.REACT_APP_REWARD_API_URL
  constructor() {
    makeAutoObservable(this);
  }

  async getRewards(customerId: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the reward store');
      return;
    }
    const res = await fetch(`${this.rewardApiUrl}/${customerId}/rewards`);
    const json = await res.json();
    if (json.data) this.rewards = json.data;
    this.isLoading = false;
    return this.rewards;
  }

  async claimReward(customerId: string, rewardId: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the reward store');
      return;
    }
    const res = await fetch(
      `${this.rewardApiUrl}/${rewardId}/claim`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId })
      }
    );
    const json = await res.json();
    console.log(json);
    this.isLoading = false;
    return json;
  }

}

export const rewardsStore = new RewardsStore();