import { Grid, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";

export const Footer: React.FC = () => {
  return (
    <Grid gap="16px" backgroundColor="brand.500" color="brand.100" mt="2em" pt="2em" pl="1em" pb="3em">
      <Grid className="footer-items">
        <Grid>
          <Heading textAlign="center" size="lg">Citron</Heading>
          <Link textAlign="center" variant="white" href="#">Who We Are</Link>
          <Link textAlign="center" variant="white" href="#">Locations</Link>
        </Grid>
      </Grid>
      <Grid gap="8px" placeContent="center">
        <Text textAlign="center" fontSize="13px">Terms and Conditions</Text>
        <Text textAlign="center" fontSize="11px">©Citron 2024 </Text>
      </Grid>
    </Grid>
  )
}