import { makeAutoObservable } from 'mobx';
import { AntavoImage } from './shared/Image';

type ChallengeProgressCriteria = {
  action: string;
  message: string;
  threshold: number;
  current: number;
  is_completed: boolean;
}

type ChallengeProgress = {
  overall: number;
  criteria: ChallengeProgressCriteria[];
}

export interface Challenge {
  id: string;
  title: string;
  description: string;
  image: AntavoImage;
  points: number;
  progress: ChallengeProgress;
  url: string;
  cta_label: string;
  completions: number;
  ends_at: string;
  starts_at: string;
}

class ChallengesStore {
  challenges: Challenge[] = [];
  isLoading: boolean = false;
  rewardApiUrl?: string = process.env.REACT_APP_REWARD_API_URL
  constructor() {
    makeAutoObservable(this);
  }

  async getChallenges(customerId: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the challenge store');
      return;
    }
    const res = await fetch(`${this.rewardApiUrl}/${customerId}/challenges`);
    const json = await res.json();
    if (json.data) {
      this.challenges = json.data;
    }
    console.log(json.data);
    this.isLoading = false;
    return this.challenges;
  }
}

export const challengesStore = new ChallengesStore();