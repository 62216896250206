import React from 'react';
import { Button, Heading, Input, Grid, Link } from '@chakra-ui/react';
import { useForm, SubmitHandler } from "react-hook-form";
import { authStore } from '../store/AuthStore';

type Inputs = {
  username: string
  password: string
}

export const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.username && data.password) {
      await authStore.login(data.username, data.password);
      document.location.pathname = '/';
    }
  }
  return (
    <Grid gap="1.5em" bg="brand.100" placeContent="center" w="100%" h="100%" paddingTop="1em" alignItems="start">
      <Heading color="brand.500" size="xs">Sign in to your Account</Heading>
      <Input variant="flushed" minW="300px" placeholder='Email*' type="email" required {...register('username')} />
      <Input variant="flushed" minW="300px" placeholder='Password*' type="password" required {...register('password')} />
      <Link justifySelf="end" type="reset">Forgot?</Link>
      <Button
        color="brand.100"
        onClick={handleSubmit(onSubmit)}
        isLoading={authStore.isLoading}
        loadingText="Loading..."
        variant="solid"
      >
        Sign In
      </Button>
      <Link textAlign="center" type="reset">Create an Account</Link>
    </Grid>
  )
}